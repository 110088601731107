import { createBrowserHistory } from 'history'; // eslint-disable-line import/no-extraneous-dependencies
import { AUTH_DATA } from 'store/reducers/authReducer';
import { storeLogout, storeRefresh } from 'store/actions';
import { Store } from 'store';
import api, { apiWithoutInterceptor } from './api';

const login = async (auth) => {
  const response = await apiWithoutInterceptor.post(
    `/Usuario/Authenticate`,
    auth.toJson()
  );
  return response.data;
};

const logout = async () => {
  const response = await api.get(`/Usuario/Logout`).catch((error) => {
    console.log(error);
    if (error.response) {
      console.log(error.response.status);
    }
  });
  console.log(response);
  return response.status === 200;
};

const refresh = async () => {
  const authData = JSON.parse(sessionStorage.getItem(AUTH_DATA));

  if (!authData) {
    const history = createBrowserHistory();
    history.push('/Login');
  } else if (
    !authData.refreshing ||
    (authData.timeStartRefreshing &&
      new Date().setSeconds(new Date().getSeconds() - 20) >
        new Date(authData.timeStartRefreshing))
  ) {
    authData.refreshing = true;
    authData.timeStartRefreshing = new Date();
    sessionStorage.setItem(AUTH_DATA, JSON.stringify(authData));

    const response = await api.get('/Usuario/RefreshToken');

    authData.refreshing = false;
    sessionStorage.setItem(AUTH_DATA, JSON.stringify(authData));

    if (response.data.state.length === 0) {
      Store.dispatch(storeLogout());
    } else {
      storeRefresh();
    }
  }
};

export const getAuthData = () => sessionStorage.getItem(AUTH_DATA);

export { login, logout, refresh };
