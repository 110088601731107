import React, { useState } from 'react';
import {
  Textbox,
  Panel,
  Autocomplete,
  Button,
  Switch,
  DatePicker,
} from 'ui/components';
import { JustifyContent } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme } from 'ui/Helpers/enums.ts';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { MaskTypes } from 'ui/Helpers/masks';
import { IManutencaoDemanda } from 'core/interfaces/HELP/demanda';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getFormaPagamentoPdvAutoComplete } from 'core/services/FIN';
import InfoPagamentoDemanda from '../ComponentesDemanda/infoPagamentoDemanda';
import ImportadorDocumentosDemanda from '../ComponentesDemanda/importadorDocumentosDemanda';

interface IClienteMessage {
  showMessage: boolean;
  message: string;
}

interface IContratoMessage {
  cycle?: string;
  message?: string;
  color?: string;
  showMessage: boolean;
}

interface IManutencaoDemandaNovoContrato extends IManutencaoDemanda {
  onSearchCentroDeCusto: (e: string) => Promise<Array<any>>;
  onSearchOperadora: (e: string) => Promise<Array<any>>;
  onSearchCliente: (e: string) => Promise<Array<any>>;
  onSearchPlano: (e: string) => Promise<Array<any>>;
  onSearchPlanoRateio: (e: string) => Promise<Array<any>>;
  getPlanoRateio: (nrSeq: number) => Promise<any>;
  onSearchPacoteDados: (e: string) => Promise<Array<any>>;
  onSearchChip: (e: string) => Promise<Array<any>>;
  onSearchPlanoModeloAparelho: (e: string) => Promise<Array<any>>;
  onSearchAparelho: (e: string) => Promise<Array<any>>;
  onSearchTituloEspecie: (e: string) => Promise<Array<any>>;
  onSelectChip: (
    chip: any,
    getClienteFromChip?: boolean,
    atualizaData?: boolean,
    atualizaDtSolicitacao?: boolean
  ) => any;
  onSelectCliente: (cliente: any, atualizaData?: boolean) => Promise<any>;
  contratoMessage: IContratoMessage;
  darEntrada: any;
  setDarEntrada: (value: boolean) => void;
  tituloReceber: any;
  setTituloReceber: (obj: any) => void;
  clienteMessage: IClienteMessage;
}

export default function ManutencaoNovoContrato({
  data,
  setData,
  onSetMessage,
  setLoading,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  infoPagamentoDefault,
  dataChip,
  setDataChip,
  onGerarContrato,
  onSearchCentroDeCusto,
  onSearchOperadora,
  onSearchCliente,
  onSearchPlano,
  onSearchPlanoRateio,
  getPlanoRateio,
  onSearchPacoteDados,
  onSearchChip,
  onSearchPlanoModeloAparelho,
  onSearchAparelho,
  onSearchTituloEspecie,
  onSelectChip,
  onSelectCliente,
  contratoMessage,
  darEntrada,
  setDarEntrada,
  tituloReceber,
  setTituloReceber,
  clienteMessage,
}: IManutencaoDemandaNovoContrato) {
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState<boolean>(
    false
  );

  const onAutoCompleteFormaPagamento = async (
    e: string
  ): Promise<Array<any>> => {
    const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
    });

    return formasPagamento;
  };

  const onSearchInformacaoBancariaEntrada = async (): Promise<Array<any>> => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: tituloReceber.nrSeqFormaPagamento,
    });

    onSetMessage(status, msg);

    return informacoesBancarias;
  };

  return (
    <>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              theme={Theme.Primary}
              title='Cliente'
              align={JustifyContent.Start}
            />
            <Panel.Body>
              {clienteMessage.showMessage && (
                <div className='row mb-2'>
                  <div className='col'>
                    <div className='card border-danger'>
                      <div className='card-body text-danger'>
                        <p className='card-text'>{clienteMessage.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Cliente'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchCliente}
                    minLengthTextSearch={3}
                    placeholder='Digite ao menos 3 caracteres para buscar Cliente'
                    selectedItem={data.cliente}
                    findTransaction={findTransaction}
                    onOpenTransaction={onOpenTransaction}
                    transactionToOpen={TransacationCodes.Cliente}
                    nrseqTransactionToOpen='nrSeqPessoaCli'
                    onSelectItem={(cliente: any) => {
                      data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
                      data.cliente = cliente;

                      onSelectCliente(cliente);
                    }}
                    dataSourceTextProperty='noPessoa'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='RG'
                    text={data.cliente?.nrRG}
                    maxLength={15}
                    visible={data.cliente?.flgFisica}
                    readOnly
                  />
                </div>
                {data.cliente?.flgFisica ? (
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='CPF'
                      text={data.cliente?.nrDocumento}
                      mask={MaskTypes.CPF}
                      readOnly
                    />
                  </div>
                ) : (
                  <div className='col'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='CNPJ'
                      text={data.cliente?.nrDocumento}
                      mask={MaskTypes.CNPJ}
                      readOnly
                    />
                  </div>
                )}
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Endereço'
                    text={data.cliente?.endereco?.noRua}
                    readOnly
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Número'
                    text={data.cliente?.endereco?.numero}
                    readOnly
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Bairro'
                    text={data.cliente?.endereco?.bairro}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Cidade'
                    text={data.cliente?.endereco?.cidade?.noCidade}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='CEP'
                    text={data.cliente?.endereco?.cep}
                    readOnly
                  />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='UF'
                    text={data.cliente?.endereco?.cidade?.estado?.cdEstado}
                    readOnly
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox label='Email' text={data.cliente?.email} readOnly />
                </div>
                <div className='col'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Telefone'
                    text={data.cliente?.fone}
                    mask={MaskTypes.CellPhone}
                    readOnly
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Plano / Linha / Aparelho'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Autocomplete
                    label='Operadora'
                    enabled={data.flgPermiteAlterar && !data.nrSeqDemanda}
                    required
                    searchDataSource={onSearchOperadora}
                    selectedItem={data.operadoraDestino}
                    onSelectItem={(operadoraDestino: any) => {
                      setData({
                        ...data,
                        operadoraDestino,
                        nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
                        plano: undefined,
                        nrSeqPlano: undefined,
                        tipoChamada: undefined,
                        nrSeqTipoChamada: undefined,
                        chip: undefined,
                        nrSeqChip: undefined,
                      });

                      setDataChip({});
                    }}
                    dataSourceTextProperty='noOperadora'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Centro de Custo'
                    enabled={data.flgPermiteAlterar}
                    searchDataSource={onSearchCentroDeCusto}
                    selectedItem={data.centroDeCusto}
                    onSelectItem={(centroDeCusto: any) =>
                      setData({
                        ...data,
                        centroDeCusto,
                        nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
                      })
                    }
                    dataSourceTextProperty='noCentroDeCusto'
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Plano'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchPlano}
                    selectedItem={data.plano}
                    onSelectItem={(plano: any) =>
                      setData({
                        ...data,
                        plano,
                        nrSeqPlano: plano.nrSeqPlano,
                        tipoChamada: undefined,
                        nrSeqTipoChamada: undefined,
                      })
                    }
                    dataSourceTextProperty='noPlano'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Pacote'
                    enabled={data.flgPermiteAlterar}
                    required
                    searchDataSource={onSearchPacoteDados}
                    selectedItem={data.tipoChamada}
                    onSelectItem={async (tipoChamada: any) => {
                      if ('nrSeqTipoChamada' in tipoChamada) {
                        const planoRateio = await getPlanoRateio(
                          tipoChamada.nrSeqTipoChamada
                        );

                        if (planoRateio) {
                          setHasPlanoRateioDefault(true);

                          setData({
                            ...data,
                            tipoChamada,
                            nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                            vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                            planoRateioEmpresa: planoRateio,
                            nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                            planoModeloAparelho: undefined,
                            nrSeqModeloAparelho: undefined,
                            parcelamentoAparelho: undefined,
                            nrSeqParcelamentoAparelho: undefined,
                            aparelho: undefined,
                            nrSeqAparelho: undefined,
                          });
                        } else {
                          setHasPlanoRateioDefault(false);

                          setData({
                            ...data,
                            tipoChamada,
                            nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                            vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                            planoRateioEmpresa: undefined,
                            nrSeqPlanoRateioEmpresa: undefined,
                            planoModeloAparelho: undefined,
                            nrSeqModeloAparelho: undefined,
                            parcelamentoAparelho: undefined,
                            nrSeqParcelamentoAparelho: undefined,
                            aparelho: undefined,
                            nrSeqAparelho: undefined,
                          });
                        }
                      } else {
                        setHasPlanoRateioDefault(false);

                        setData({
                          ...data,
                          tipoChamada,
                          nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                          vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                          planoRateioEmpresa: undefined,
                          nrSeqPlanoRateioEmpresa: undefined,
                          planoModeloAparelho: undefined,
                          nrSeqModeloAparelho: undefined,
                          parcelamentoAparelho: undefined,
                          nrSeqParcelamentoAparelho: undefined,
                          aparelho: undefined,
                          nrSeqAparelho: undefined,
                        });
                      }

                      setDarEntrada(false);
                      setDataChip({});
                    }}
                    dataSourceTextProperty='noTipoChamada'
                  />
                </div>
                <div className='col-2'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='Valor Pacote'
                    readOnly={
                      !data.flgPermiteAlterar ||
                      !data.tipoChamada?.flgPermiteEditar
                    }
                    text={data.vlrPacoteEncargoManual}
                    mask={MaskTypes.Decimal}
                    onChangedValue={(vlrPacoteEncargoManual: any) => {
                      setData({
                        ...data,
                        vlrPacoteEncargoManual,
                      });
                    }}
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Plano de Rateio Empresa'
                    searchDataSource={onSearchPlanoRateio}
                    enabled={
                      data.flgPermiteAlterar &&
                      !!data.empresa?.nrSeqEmpresa &&
                      !hasPlanoRateioDefault
                    }
                    placeholder={
                      !data.empresa?.nrSeqEmpresa ? 'Seleciona a Empresa' : ''
                    }
                    selectedItem={data.planoRateioEmpresa}
                    onSelectItem={(planoRateioEmpresa: any) => {
                      setData({
                        ...data,
                        planoRateioEmpresa,
                        nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
                      });
                    }}
                    dataSourceTextProperty='noPlano'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='DDD'
                    readOnly={!data.flgPermiteAlterar}
                    visible={data.operadoraDestino?.nrSeqOperadora === 20}
                    text={dataChip.nrPrefixo}
                    onChangedValue={(nrPrefixo: number) => {
                      setDataChip({
                        ...dataChip,
                        nrPrefixo,
                      });
                    }}
                  />
                </div>
                <div className='col-3'>
                  <Autocomplete
                    label='Pesquisar Linha'
                    enabled={
                      data.flgPermiteAlterar &&
                      !!data.empresa?.nrSeqEmpresa &&
                      !data.nrSeqDemanda
                    }
                    required
                    searchDataSource={onSearchChip}
                    selectedItem={data.chip}
                    onSelectItem={async (chip: any) => {
                      await onSelectChip(chip);
                    }}
                    dataSourceTextProperty='linhaFormatada'
                  />
                </div>
                {data.nrSeqTipoChamada &&
                  data.plano?.nrSeqPlano &&
                  data.nrSeqOperadoraDestino === 20 && (
                    <div className='col-auto'>
                      {/* @ts-expect-error */}
                      <Switch
                        formControl
                        label='Números Especiais'
                        disabled={!data.flgPermiteAlterar && data.nrSeqDemanda}
                        checked={dataChip?.flgNumeroEspecial}
                        onChange={(flgNumeroEspecial: boolean) =>
                          setDataChip({
                            ...dataChip,
                            flgNumeroEspecial,
                          })
                        }
                      />
                    </div>
                  )}
                <div className='col-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    required={data.operadoraDestino?.nrSeqOperadora === 20}
                    label='ICCID'
                    readOnly={data.flgPermiteAlterar}
                    maxLength={20}
                    text={dataChip.nrIccid}
                    onChangedValue={(nrIccid: string): void => {
                      setDataChip({
                        ...dataChip,
                        nrIccid,
                      });

                      setData({
                        ...data,
                        nrIccid,
                      });
                    }}
                    onBlur={(_: any, param: string) => {
                      if (param && param.length < 20 && param.length > 0) {
                        onSetMessage(
                          1,
                          'O ICCID informando não contem os 20 dígitos.'
                        );
                      }
                    }}
                  />
                </div>
                <div className='col'>
                  <Autocomplete
                    label='Modelos de Aparelhos em Estoque'
                    enabled={data.flgPermiteAlterar}
                    visible={data.plano?.nrSeqPlano}
                    searchDataSource={onSearchPlanoModeloAparelho}
                    selectedItem={data.planoModeloAparelho}
                    onSelectItem={(planoModeloAparelho: any) => {
                      setData({
                        ...data,
                        planoModeloAparelho,
                        nrSeqModeloAparelho:
                          planoModeloAparelho.nrSeqModeloAparelho,
                        parcelamentoAparelho: {
                          nrParcelas: planoModeloAparelho.nrParcelas,
                          vlrOriginal: planoModeloAparelho.vlrTotal,
                        },
                        aparelho: undefined,
                        nrSeqAparelho: undefined,
                      });

                      setDarEntrada(false);
                      setTituloReceber({});
                    }}
                    dataSourceTextProperty='noModeloAparelho'
                  />
                </div>
              </div>
              {data.planoModeloAparelho?.nrSeqModeloAparelho && (
                <>
                  <div className='row mb-3'>
                    <div className='col'>
                      <Autocomplete
                        label='IMEI do Aparelho'
                        enabled={data.flgPermiteAlterar}
                        required={!!data.nrSeqModeloAparelho}
                        visible={
                          data.planoModeloAparelho?.nrSeqModeloAparelho != null
                        }
                        searchDataSource={onSearchAparelho}
                        selectedItem={data.aparelho}
                        onSelectItem={(aparelho: any) =>
                          setData({
                            ...data,
                            aparelho,
                            nrSeqAparelho: aparelho.nrSeqAparelho,
                          })
                        }
                        dataSourceTextProperty='nrImei'
                      />
                    </div>
                    <div className='col-1'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Parcelas'
                        required={!!data.nrSeqModeloAparelho}
                        readOnly={
                          !data.flgPermiteAlterar &&
                          !data.parcelamentoAparelho?.flgAutorizaEditar
                        }
                        text={data.parcelamentoAparelho?.nrParcelas}
                        mask={MaskTypes.Integer}
                        onChangedValue={(nrParcelas: any) =>
                          setData({
                            ...data,
                            parcelamentoAparelho: {
                              ...data.parcelamentoAparelho,
                              nrParcelas,
                            },
                          })
                        }
                      />
                    </div>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Valor Aparelho'
                        readOnly
                        text={data.parcelamentoAparelho?.vlrOriginal}
                        mask={MaskTypes.Currency}
                      />
                    </div>
                    <div className='col'>
                      {/* @ts-expect-error */}
                      <Textbox
                        label='Valor Parcela'
                        readOnly
                        text={
                          data.parcelamentoAparelho?.vlrOriginal /
                          data.parcelamentoAparelho?.nrParcelas
                        }
                        mask={MaskTypes.Currency}
                      />
                    </div>
                    <div className='col-2'>
                      {/* @ts-expect-error */}
                      <Switch
                        formControl
                        label='Dar Entrada'
                        checked={darEntrada}
                        onChange={(value: boolean) => {
                          setDarEntrada(value);
                          setTituloReceber({});
                        }}
                      />
                    </div>
                  </div>
                  {darEntrada && (
                    <div className='row mb-3'>
                      <div className='col'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Valor da Entrada'
                          required={darEntrada}
                          text={tituloReceber.vlrTitulo ?? 0}
                          onChangedValue={(vlrTitulo: number) =>
                            setTituloReceber({
                              ...tituloReceber,
                              vlrTitulo,
                            })
                          }
                          mask={MaskTypes.Decimal}
                        />
                      </div>
                      <div className='col-2'>
                        <DatePicker
                          label='Vencimento da Entrada'
                          required={darEntrada}
                          text={tituloReceber.dtVencimento}
                          onChange={(dtVencimento: string) =>
                            setTituloReceber({
                              ...tituloReceber,
                              dtVencimento,
                            })
                          }
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Tipo Receita'
                          required={darEntrada}
                          searchDataSource={onSearchTituloEspecie}
                          selectedItem={tituloReceber.tituloEspecie}
                          onSelectItem={(tituloEspecie: any) =>
                            setTituloReceber({
                              ...tituloReceber,
                              tituloEspecie,
                              nrSeqTituloEspecie:
                                tituloEspecie.nrSeqTituloEspecie,
                            })
                          }
                          dataSourceTextProperty='noEspecie'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Forma Pagamento da Entrada'
                          required={darEntrada}
                          searchDataSource={onAutoCompleteFormaPagamento}
                          selectedItem={tituloReceber.formaPagamento}
                          onSelectItem={(formaPagamento: any) =>
                            setTituloReceber({
                              ...tituloReceber,
                              formaPagamento,
                              nrSeqFormaPagamento:
                                formaPagamento.nrSeqFormaPagamento,
                            })
                          }
                          dataSourceTextProperty='noFormaPagamento'
                        />
                      </div>
                      <div className='col'>
                        <Autocomplete
                          label='Informação Bancária da Entrada'
                          required={
                            tituloReceber.formaPagamento?.flgGrupo === 'D'
                          }
                          visible={
                            tituloReceber.formaPagamento &&
                            tituloReceber.formaPagamento?.flgGrupo === 'D'
                          }
                          searchDataSource={onSearchInformacaoBancariaEntrada}
                          selectedItem={tituloReceber.informacaoBancaria}
                          onSelectItem={(informacaoBancaria: any) =>
                            setTituloReceber({
                              ...tituloReceber,
                              informacaoBancaria,
                              nrSeqPessoaInformacaoBancaria:
                                informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                            })
                          }
                          dataSourceTextProperty='noDescricao'
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Informação Pagamento'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row mb-3'>
                <InfoPagamentoDemanda
                  data={data}
                  setData={setData}
                  infoPagamentoDefault={infoPagamentoDefault}
                  onSetMessage={onSetMessage}
                  setLoading={setLoading}
                />
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header
              title='Contrato'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              {clienteMessage.showMessage && (
                <div className='row mb-2'>
                  <div className='col'>
                    <div className='card border-danger'>
                      <div className='card-body text-danger'>
                        <p className='card-text'>{clienteMessage.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className='row mb-3'>
                <div className='col-2'>
                  <DatePicker
                    label='Data de Início de Contrato'
                    required
                    readOnly={!data.flgPermiteAlterar}
                    text={data.dtSolicitacao}
                    onChange={(dtSolicitacao: string) => {
                      setData({
                        ...data,
                        dtSolicitacao,
                      });
                    }}
                  />
                </div>
                {'cycle' in contratoMessage &&
                  dataChip.nrSeqChip !== undefined && (
                    <div className='col'>
                      <div className={`card border-${contratoMessage.color}`}>
                        <div
                          className={`card-body text-${contratoMessage.color}`}
                        >
                          <p className='card-text mb-0'>
                            {contratoMessage.cycle}
                          </p>
                          {contratoMessage.message &&
                            contratoMessage.message.length > 0 && (
                              <p className='card-text'>
                                {contratoMessage.message}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className='row mb-3'>
                <div className='col-2 align-self-center'>
                  <Button
                    theme={Theme.Success}
                    template={Button.Templates.Default}
                    className='w-100'
                    text='Gerar Contrato'
                    onClick={onGerarContrato}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row mb-3'>
        <ImportadorDocumentosDemanda
          data={data}
          documentos={documentos}
          setDocumentos={setDocumentos}
          setDataListDocRemove={setDataListDocRemove}
          onSetMessage={onSetMessage}
        />
      </div>
    </>
  );
}
