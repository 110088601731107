import {
  geraSequenciaTipoReceita,
  getTipoReceitaAcertoList,
} from 'core/services/FIN/tipoReceitaAcerto';
import {
  getClienteAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';

import {
  getRemetenteAutoComplete,
  getDestinatarioAutoComplete,
} from 'core/services/FRO/remetente_Destinatario';

import {
  getAcertoMotoristaAutoComplete,
  AdicionarReceitas,
  RecalculaResumo,
  printReciboReceitas,
  saveAcertoMotorista,
} from 'core/services/FIN/acertoMotorista';

import {
  changeAcerto,
  GetReceitasAcerto,
} from 'core/services/FIN/acertoMotoristaReceita';

import {
  getCidadeEstadoAutoComplete,
  getEmpresaAutoComplete,
} from 'core/services/SEG';
import React, { useRef, forwardRef, useState, useEffect } from 'react';
import {
  DatePicker,
  Button,
  GridView,
  Notification,
  Panel,
  Autocomplete,
  Textbox,
  Switch,
  FileUpload,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  BootstrapSizes,
  JustifyContent,
  MimeTypes,
  ResponseStatus,
  Theme,
  toBase64,
} from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import ModalReceitas from './modalReceitas';

const Receitas = forwardRef(
  ({
    data,
    registryKey,
    setData,
    acerto,
    findTransaction,
    onOpenTransaction,
    onOpenReport,
    transaction,
    setTransaction,
    bonus,
    setLoading,
  }) => {
    const [receitas, setReceitas] = useState({});
    const [acertoNew, setAcertoNew] = useState({});
    const [liberaDocumento, setLiberaDocumento] = useState({});
    const gridView = useRef();
    const [message, onSetMessage] = useState();
    const [modalReceitas, setModalReceitas] = useState(false);

    const [importedFile, setImportedFile] = useState([]);
    const setMessage = (status, msg) => {
      if (msg)
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
    };

    const onClickRemoveParcela = async (selectedValue, datasource) => {
      let exclusaoParcela = [];
      if (
        selectedValue.nrSeqAcertoMotoristaReceita === 0 ||
        selectedValue.nrSeqAcertoMotoristaReceita === null ||
        selectedValue.nrSeqAcertoMotoristaReceita === undefined
      ) {
        exclusaoParcela = datasource.filter(
          (Receita) => Receita !== selectedValue
        );
      } else {
        exclusaoParcela = datasource.filter(
          (Receita) => Receita !== selectedValue
        );
        const parcelaexclusao = datasource.filter(
          (Receita) => Receita === selectedValue
        );
        parcelaexclusao[0].status = GridView.EnumStatus.Remover;
        exclusaoParcela.push(parcelaexclusao[0]);

        if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
          data.outrasDespesas.forEach((item) => {
            if (item.tipoDespesaAcerto.flgComissao === true) {
              item.vlrDespesa -= parcelaexclusao[0].vlrComissao;
            }
          });
        }
      }
      if (gridView && gridView.current)
        gridView.current.setDataSource(exclusaoParcela);

      const nrSeqAcerto = data.nrSeqAcertoMotorista;

      const valor = {
        ...data,
        ...acerto,
        acertoMotoristaReceitaS: exclusaoParcela,
      };

      const obj = await RecalculaResumo(valor);

      const lista = [];

      if (obj.acertoMotoristaReceitaS !== null) {
        if (obj.acertoMotoristaReceitaS !== undefined) {
          for (let i = 0; i < obj.acertoMotoristaReceitaS.length; ) {
            if (obj.acertoMotoristaReceitaS[i].flgGeraComissao === true) {
              lista.push(obj.acertoMotoristaReceitaS[i]);
            }
            i += 1;
          }
        }
      }

      if (obj.nrSeqAcertoMotorista > 0) {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.premios,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: lista,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalReceitas: obj.totalReceitas,
          totalPeso: obj.totalPeso,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      } else {
        setData({
          veiculoComp: obj.veiculoComp,
          veiculoCompB: obj.veiculoCompB,
          veiculoCompC: obj.veiculoCompC,
          placasVinculadas: obj.placasVinculadas,
          nrSeqAcertoMotorista: nrSeqAcerto,
          coletaEmbarqueS: obj.coletaEmbarqueS,
          viagens: obj.viagens,
          premios: obj.premios,
          outrasDespesas: obj.outrasDespesas,
          outrasReceitasComissao: lista,
          abastecimentos: obj.abastecimentos,
          acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
          acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
          acertoMotoristaTipoDescontoPeriodo:
            obj.acertoMotoristaTipoDescontoPeriodo,
          acertoMotoristaTipoDescontoPeriodoS:
            obj.acertoMotoristaTipoDescontoPeriodo,
          adiantamentos: obj.adiantamentos,
          bonus: obj.bonus,
          cdAcertoMotorista: obj.cdAcertoMotorista,
          cliente: obj.cliente,
          dtAcerto: obj.dtAcerto,
          dtAlteracao: obj.dtAlteracao,
          dtCadastro: obj.dtCadastro,
          dtCheque: obj.dtCheque,
          extratoFinanceiroS: obj.extratoFinanceiroS,
          formaPagamento: obj.formaPagamento,
          manutencaoVeiculoS: obj.manutencaoVeiculoS,
          mediaKM: obj.mediaKM,
          mediaOutrosKM: obj.mediaOutrosKM,
          motorista: obj.motorista,
          motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
          nrCheque: obj.nrCheque,
          nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
          nrSeqPessoaMot: obj.nrSeqPessoaMot,
          nrSeqTituloPagar: obj.nrSeqTituloPagar,
          nrSeqTituloReceber: obj.nrSeqTituloReceber,
          nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
          nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
          nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
          nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
          pedagioS: obj.pedagioS,
          pesoReceitas: obj.pesoReceitas,
          pesoTotal: obj.pesoTotal,
          pesoTotalReceitas: obj.pesoTotalReceitas,
          pessoaMot: obj.pessoaMot,
          recebimentos: obj.recebimentos,
          tipoAcertoMotorista: obj.tipoAcertoMotorista,
          tipoReceita: obj.tipoReceita,
          totalAbastecimentos: obj.totalAbastecimentos,
          totalAbastecimentosVista: obj.totalAbastecimentosVista,
          totalAdiantamentos: obj.totalAdiantamentos,
          totalComissao: obj.totalComissao,
          totalConhecimento: obj.totalConhecimento,
          totalKMRodados: obj.totalKMRodados,
          totalLitros: obj.totalLitros,
          totalManutencoes: obj.totalManutencoes,
          totalManutencoesAVista: obj.totalManutencoesAVista,
          totalMotorista: obj.totalMotorista,
          totalOutrasDespesas: obj.totalOutrasDespesas,
          totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
          totalPedagios: obj.totalPedagios,
          totalPeso: obj.totalPeso,
          totalReceitas: obj.totalReceitas,
          veiculo: obj.veiculo,
          vlrAcerto: obj.vlrAcerto,
          vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
          vlrBonus: obj.vlrBonus,
          vlrBonusRecebido: obj.vlrBonusRecebido,
          vlrSaldoCartao: obj.vlrSaldoCartao,
        });
      }
    };

    const onClickEditParcela = async (selectedValue, datasource) => {
      const receita = datasource.filter((item) => item !== selectedValue);
      const receitaedicao = datasource.filter((item) => item === selectedValue);

      if (receitaedicao[0].nrSeqAcertoMotoristaReceita > 0) {
        receitaedicao[0].status = GridView.EnumStatus.Remover;
        receita.push(receitaedicao[0]);
      }

      if (gridView && gridView.current) gridView.current.setDataSource(receita);

      setReceitas({
        ...receitaedicao[0],
        nrSeqAcertoMotoristaReceita: null,
        nrSeqTituloReceber: null,
      });

      setData({
        ...data,
        acertoMotoristaReceitaS: receita,
        premios: data.viagens,
      });
      // setEdicao(true);
    };

    const onClickDownload = async (e) => {
      const linkSource = `data:image/png;base64, ${
        e.documento.noImagemBase64 ?? e.documento.noImagem
      }`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = e.documento.noArquivo;
      downloadLink.click();
    };

    const onOpenTituloReceber = (selectedValue) => {
      const transactionContaChamada = findTransaction(
        TransacationCodes.TituloReceber
      );

      if (transactionContaChamada) {
        transactionContaChamada.registryKey = selectedValue.nrSeqTituloReceber;

        onOpenTransaction(transactionContaChamada, true);
      }
    };

    const onPrintRecibo = async (param) => {
      const recibo = await printReciboReceitas(param.nrSeqTituloReceber);

      onOpenReport(transaction.noTransacao, recibo);
    };

    const columns = [
      {
        key: 'nrSeqAcertoMotoristaReceita',
        visible: true,
        type: GridView.ColumnTypes.Checkbox,
      },

      {
        key: 'nrDocumento',
        title: 'Nr. Documento',
      },
      {
        key: 'dtReceita',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'dtFrete',
        title: 'Data Frete',
        format: GridView.DataTypes.Date,
        visible: false,
      },
      {
        key: 'vlrReceita',
        title: 'Valor',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'tipoReceitaAcerto.noTipoReceitaAcerto',
        title: 'Tipo Receita',
      },
      {
        key: 'formaPagamento.noFormaPagamento',
        title: 'Forma Pagamento',
      },
      {
        key: 'cliente.noPessoa',
        title: 'Cliente',
        format: GridView.DataTypes.Text,
      },
      {
        key: 'remetente.noPessoa',
        title: 'Remetente',
        format: GridView.DataTypes.Text,
        visible: false,
      },
      {
        key: 'destinatario.noPessoa',
        title: 'Destinatário',
        visible: false,
      },
      {
        key: 'enderecoRemetente.noCidadeEstado',
        title: 'Origem',
      },
      {
        key: 'enderecoDestino.noCidadeEstado',
        title: 'Destino',
      },
      {
        key: 'noObservacao',
        title: 'Obs',
        visible: false,
      },
      { key: 'flgQuitado', title: 'Quitado' },
      {
        key: 'nrSeqTituloReceber',
        type: GridView.ColumnTypes.Button,
        title: 'Visualizar Titulo',
        onClick: (e) => onOpenTituloReceber(e),
        theme: Theme.Warning,
        icon: 'folder-open',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Visualizar Titulo',
        tooltipDirection: 'bottom',
      },
      { key: 'documento.noArquivo', title: 'Documento' },
      {
        key: 'nrSeqTituloPagarDocumento',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickDownload(e, datasource),
        theme: Theme.Success,
        icon: 'download',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Download',
        visibleDynamic: 'flgExibeDownload',
      },
      {
        key: 'nrSeqAcertoMotoristaReceita',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditParcela(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqAcertoMotoristaReceita',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickRemoveParcela(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
      {
        key: 'nrSeqTituloReceber',
        type: GridView.ColumnTypes.Button,
        theme: Theme.Warning,
        onClick: (e) => onPrintRecibo(e),
        icon: 'print',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Imprimir Recibo',
        tooltipDirection: 'bottom',
        visibleDynamic: 'flgExisteFatura',
      },
    ];

    const load = async () => {
      if (data !== undefined && data !== null) {
        if (
          data.acertoMotoristaReceitaS !== undefined &&
          data.acertoMotoristaReceitaS !== null
        ) {
          data.acertoMotoristaReceitaS.forEach((item) => {
            item.flgExisteTitulo = item.nrSeqTituloReceber > 0;

            if (item.vlrSaldoTit > 0) {
              item.flgQuitado = false;
            } else {
              item.flgQuitado = true;
            }
          });

          if (gridView && gridView.current)
            gridView.current.setDataSource(data.acertoMotoristaReceitaS);
        }
      }

      setMessage(
        ResponseStatus.Error,
        'Receitas Adicionadas, serão salvas automaticas no sistema.'
      );
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [data.nrSeqPessoaCli, registryKey]);

    useEffect(() => {
      if (receitas.tarifa > 0) {
        if (receitas.peso > 0) {
          let vlrReceitaTotal = (receitas.peso / 1000) * receitas.tarifa;

          vlrReceitaTotal = vlrReceitaTotal.toFixed(2);

          setReceitas({ ...receitas, vlrReceita: vlrReceitaTotal });
        }
      }
    }, [receitas.tarifa]);

    useEffect(() => {
      if (receitas.tarifa > 0) {
        if (receitas.peso > 0) {
          const vlrReceitaTotal = (receitas.peso / 1000) * receitas.tarifa;
          setReceitas({ ...receitas, vlrReceita: vlrReceitaTotal });
        }
      }
    }, [receitas.peso]);

    useEffect(() => {
      (async function func() {
        if (receitas !== null && receitas !== undefined) {
          if (receitas.nrSeqTipoReceitaAcerto > 0) {
            if (
              receitas.tipoReceitaAcerto !== null &&
              receitas.tipoReceitaAcerto !== undefined
            ) {
              if (receitas.tipoReceitaAcerto.flgGeraSequencia) {
                const tipoReceita = await geraSequenciaTipoReceita(
                  receitas.tipoReceitaAcerto
                );

                setReceitas({
                  ...receitas,
                  nrDocumento: tipoReceita.nrDocumentoSequencia,
                });

                setLiberaDocumento({ flgLiberaDocumento: true });
              } else {
                setLiberaDocumento({ flgLiberaDocumento: false });
              }
            } else {
              setLiberaDocumento({ flgLiberaDocumento: false });
              setReceitas({ ...receitas, flgGeraSegundoPagamento: false });
            }
          } else {
            setLiberaDocumento({ flgLiberaDocumento: false });
            setReceitas({ ...receitas, flgGeraSegundoPagamento: false });
          }
        } else {
          setLiberaDocumento({ flgLiberaDocumento: false });
          setReceitas({ ...receitas, flgGeraSegundoPagamento: false });
        }
      })();
    }, [receitas.nrSeqTipoReceitaAcerto]);

    const onSearchCliente = async (e) => {
      const { status, message: msg, clientes } = await getClienteAutoComplete({
        noPessoa: e,
      });
      if (msg) onSetMessage(status, msg);
      return clientes;
    };
    const onSearchCidadeOrigem = async (e) => {
      const {
        status,
        message: msg,
        cidades,
      } = await getCidadeEstadoAutoComplete({
        noCidade: e,
      });
      if (msg) onSetMessage(status, msg);
      return cidades;
    };
    const onSearchCidadeDestino = async (e) => {
      const {
        status,
        message: msg,
        cidades,
      } = await getCidadeEstadoAutoComplete({
        noCidade: e,
      });
      if (msg) onSetMessage(status, msg);
      return cidades;
    };
    const onSearchTipoReceita = async (e) => {
      const {
        status,
        message: msg,
        data: tipoReceita,
      } = await getTipoReceitaAcertoList({
        noTipoReceita: e,
      });
      if (msg) onSetMessage(status, msg);
      return tipoReceita;
    };

    const onSearchFormaPagamento = async (e) => {
      const {
        status,
        message: msg,
        formasPagamento,
      } = await getFormaPagamentoAutoComplete({
        noFormaPagamento: e,
        flgAtivo: true,
        nrSeqEmpresa: data.nrSeqEmpresa,
      });
      if (msg) onSetMessage(status, msg);
      return formasPagamento;
    };

    const onAutoCompleteDestinatario = async (e) => {
      const {
        status,
        message: msg,
        data: destinatarios,
      } = await getDestinatarioAutoComplete({
        flgTodos: true,
        noPessoa: e,
      });

      if (msg) onSetMessage(status, msg);

      return destinatarios;
    };
    const onSearchEmpresa = async (e) => {
      const { status, message: msg, empresas } = await getEmpresaAutoComplete({
        noEmpresa: e,
      });
      if (msg) onSetMessage(status, msg);
      return empresas;
    };

    const onAutoCompleteRemetente = async (e) => {
      const {
        status,
        message: msg,
        data: remetentes,
      } = await getRemetenteAutoComplete({
        noPessoa: e,
      });

      if (msg) onSetMessage(status, msg);

      return remetentes;
    };

    const onAutoCompleteAcertoMotorista = async (e) => {
      const {
        status,
        message: msg,
        data: acertos,
      } = await getAcertoMotoristaAutoComplete({
        cdAcertoMotorista: e,
      });

      if (msg) onSetMessage(status, msg);

      return acertos;
    };

    const refreshAcerto = async (
      datasourceReceitas,
      datasourceOutrasReceitasComissao
    ) => {
      const valorObj = {
        ...data,
        ...acerto,
        acertoMotoristaReceitaS: datasourceReceitas,
        outrasReceitasComissao: datasourceOutrasReceitasComissao,
      };

      const obj = await RecalculaResumo(valorObj);

      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    };

    const onChangeAcerto = async () => {
      const selecteds = gridView.current.getCheckBoxValuesAt(0);
      if (selecteds.length === 0)
        setMessage(
          Theme.Danger,
          'Necessário selecionar as receitas para troca de acerto'
        );
      if (selecteds.length > 0) {
        const { status, message: msg } = await changeAcerto(
          selecteds.map((row) => row[0].value),
          acertoNew.nrSeqAcertoMotorista
        );

        setMessage(
          status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          msg
        );

        if (status === ResponseStatus.Success) {
          let datasource = gridView.current.getDataSource();
          for (let i = 0; i < selecteds.length; i += 1) {
            const remove = datasource.find(
              (x) => x.nrSeqAcertoMotoristaReceita === selecteds[i][0].value
            );

            datasource = datasource.filter((x) => x !== remove);
          }
          gridView.current.setDataSource(datasource);

          let { outrasReceitasComissao } = data;
          for (let i = 0; i < outrasReceitasComissao.length; i += 1) {
            const remove = outrasReceitasComissao.find(
              (x) => x.nrSeqAcertoMotoristaReceita === selecteds[i][0].value
            );

            outrasReceitasComissao = outrasReceitasComissao.filter(
              (x) => x !== remove
            );
          }

          refreshAcerto(datasource, outrasReceitasComissao);
        }
      }
    };

    const onModalReceitas = async () => {
      if (
        data.nrSeqAcertoMotorista === null ||
        data.nrSeqAcertoMotorista === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Para buscar as receitas, por favor salve o acerto primeiro'
        );
        return;
      }

      setModalReceitas(true);
    };

    const onAdicionarSegundoPagamento = async () => {
      setReceitas({ ...receitas, flgGeraSegundoPagamento: true });
    };

    const onAdicionarReceita = async () => {
      if (
        data.nrSeqAcertoMotorista === null ||
        data.nrSeqAcertoMotorista === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Para Adicionar uma Receita, por favor salve o acerto primeiro'
        );
        return;
      }

      if (data.dtAcerto !== null && data.dtAcerto !== undefined) {
        setMessage(
          ResponseStatus.Error,
          'Você não pode adicionar uma Receita a um acerto ja efetivado'
        );
        return;
      }

      if (data !== null || data !== undefined) {
        if (data.viagens === null || data.viagens === undefined) {
          setData({ ...data, viagens: [] });

          data.viagens = [];
        }
      }

      const itensGridView = gridView.current
        ? gridView.current.getDataSource()
        : [];

      const itensParaRemover = itensGridView.filter(
        (item) => item.status === 'Remover'
      );

      const receita = { nrSeqAcertoMotorista: data.nrSeqAcertoMotorista };

      const acertoMotoristaReceitaS = await GetReceitasAcerto(receita);

      const novaSegundaLista = acertoMotoristaReceitaS.filter(
        (item) =>
          !itensParaRemover.some(
            (removerItem) =>
              removerItem.nrSeqAcertoMotoristaReceita ===
              item.nrSeqAcertoMotoristaReceita
          )
      );

      const resultadoFinal = [...novaSegundaLista, ...itensParaRemover];

      const datasource = resultadoFinal ?? [];

      if (importedFile[0] !== undefined) {
        if (importedFile[0] !== null) {
          let noImagemBase64 = await toBase64(importedFile[0]);
          noImagemBase64 = String(noImagemBase64).substring(
            String(noImagemBase64).indexOf(',') + 1
          );

          const documento = {
            noArquivo: importedFile[0].name,
            noTipoArquivo: importedFile[0].type,
            noTamanho: String(importedFile[0].size),
            flgExibeDownload: false,
            nrSeqTituloPagar: data.nrSeqTituloPagar,
            tipoDocumento: data.tipoDocumento,
            noImagemBase64,
            status: GridView.EnumStatus.Inserir,
          };

          // setReceitas({ ...receitas, documento });

          receitas.documento = documento;
        } else {
          const documento = {
            noArquivo: ' ',
          };
          receitas.documento = documento;
        }
      } else {
        const documento = {
          noArquivo: ' ',
        };
        receitas.documento = documento;
      }

      if (receitas.status !== GridView.EnumStatus.Alterar) {
        receitas.status = GridView.EnumStatus.Inserir;
      }

      if (receitas.flgAvista === false) {
        if (
          receitas.nrSeqFormaPagamento === null ||
          receitas.nrSeqFormaPagamento === undefined
        ) {
          setMessage(
            ResponseStatus.Error,
            'Não é possivel adicionar receitas, sem selecionar a forma de pagamento'
          );
          return;
        }
      }

      if (receitas.flgGeraSegundoPagamento === true) {
        if (
          receitas.nrSeqFormaPagamentoVinculado === null ||
          receitas.nrSeqFormaPagamentoVinculado === undefined
        ) {
          setMessage(
            ResponseStatus.Error,
            'Não é possivel adicionar receitas, sem selecionar a forma de pagamento Vinculado'
          );
          return;
        }

        if (
          receitas.vlrReceitaVinculado === null ||
          receitas.vlrReceitaVinculado === undefined
        ) {
          setMessage(
            ResponseStatus.Error,
            'Não é possivel adicionar receitas, sem inserir o valor das receitas Vinculados'
          );
          return;
        }

        if (
          receitas.dtReceitaVinculado === null ||
          receitas.dtReceitaVinculado === undefined
        ) {
          setMessage(
            ResponseStatus.Error,
            'Não é possivel adicionar receitas, sem inserir a data da receita vinculada'
          );
          return;
        }
      }

      if (
        receitas.nrSeqPessoaCli === null ||
        receitas.nrSeqPessoaCli === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel adicionar receitas, sem selecionar o Cliente'
        );
        return;
      }

      if (
        receitas.nrSeqTipoReceitaAcerto === null ||
        receitas.nrSeqTipoReceitaAcerto === undefined
      ) {
        setMessage(
          ResponseStatus.Error,
          'Não é possivel adicionar receitas, sem selecionar o tipo de receita'
        );
        return;
      }

      setLoading(true);

      const valor = {
        ...data,
        ...acerto,
        acertoMotoristaReceita: receitas,
        acertoMotoristaReceitaS: datasource,
      };

      const { value: objAddReceita } = await AdicionarReceitas(valor);

      setTransaction(transaction ? transaction.nrSeqTransacao : 0);
      const valorSave = {
        ...acerto,
        ...data,
        ...bonus,
        acertoMotoristaReceita: receitas,
        acertoMotoristaReceitaS: objAddReceita.acertoMotoristaReceitaS,
        nrSeqTransacao: transaction.nrSeqTransacao,
      };

      const { status, message: msg, value: obj } = await saveAcertoMotorista(
        valorSave
      );

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      if (status === ResponseStatus.Success) {
        if (gridView && gridView.current)
          gridView.current.setDataSource(obj.acertoMotoristaReceitaS);

        const lista = [];

        if (obj.acertoMotoristaReceitaS !== null) {
          if (obj.acertoMotoristaReceitaS !== undefined) {
            for (let i = 0; i < obj.acertoMotoristaReceitaS.length; ) {
              if (obj.acertoMotoristaReceitaS[i].flgGeraComissao === true) {
                lista.push(obj.acertoMotoristaReceitaS[i]);
              }
              i += 1;
            }
          }
        }

        if (obj.viagens !== null || obj.viagens !== undefined) {
          if (obj.nrSeqAcertoMotorista > 0) {
            setData({
              veiculoComp: obj.veiculoComp,
              veiculoCompB: obj.veiculoCompB,
              veiculoCompC: obj.veiculoCompC,
              placasVinculadas: obj.placasVinculadas,
              coletaEmbarqueS: obj.coletaEmbarqueS,
              viagens: obj.viagens,
              premios: obj.viagens,
              outrasDespesas: obj.outrasDespesas,
              outrasReceitasComissao: lista,
              abastecimentos: obj.abastecimentos,
              acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
              acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
              acertoMotoristaTipoDescontoPeriodo:
                obj.acertoMotoristaTipoDescontoPeriodo,
              acertoMotoristaTipoDescontoPeriodoS:
                obj.acertoMotoristaTipoDescontoPeriodo,
              adiantamentos: obj.adiantamentos,
              bonus: obj.bonus,
              cdAcertoMotorista: obj.cdAcertoMotorista,
              cliente: obj.cliente,
              dtAcerto: obj.dtAcerto,
              dtAlteracao: obj.dtAlteracao,
              dtCadastro: obj.dtCadastro,
              dtCheque: obj.dtCheque,
              extratoFinanceiroS: obj.extratoFinanceiroS,
              formaPagamento: obj.formaPagamento,
              manutencaoVeiculoS: obj.manutencaoVeiculoS,
              mediaKM: obj.mediaKM,
              mediaOutrosKM: obj.mediaOutrosKM,
              motorista: obj.motorista,
              motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
              nrCheque: obj.nrCheque,
              nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
              nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
              nrSeqPessoaMot: obj.nrSeqPessoaMot,
              nrSeqTituloPagar: obj.nrSeqTituloPagar,
              nrSeqTituloReceber: obj.nrSeqTituloReceber,
              nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
              nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
              nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
              nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
              pedagioS: obj.pedagioS,
              pesoReceitas: obj.pesoReceitas,
              pesoTotal: obj.pesoTotal,
              pesoTotalReceitas: obj.pesoTotalReceitas,
              pessoaMot: obj.pessoaMot,
              recebimentos: obj.recebimentos,
              tipoAcertoMotorista: obj.tipoAcertoMotorista,
              tipoReceita: obj.tipoReceita,
              totalAbastecimentos: obj.totalAbastecimentos,
              totalAbastecimentosVista: obj.totalAbastecimentosVista,
              totalAdiantamentos: obj.totalAdiantamentos,
              totalComissao: obj.totalComissao,
              totalConhecimento: obj.totalConhecimento,
              totalKMRodados: obj.totalKMRodados,
              totalLitros: obj.totalLitros,
              totalManutencoes: obj.totalManutencoes,
              totalManutencoesAVista: obj.totalManutencoesAVista,
              totalMotorista: obj.totalMotorista,
              totalOutrasDespesas: obj.totalOutrasDespesas,
              totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
              totalPedagios: obj.totalPedagios,
              totalReceitas: obj.totalReceitas,
              totalPeso: obj.totalPeso,
              veiculo: obj.veiculo,
              vlrAcerto: obj.vlrAcerto,
              vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
              vlrBonus: obj.vlrBonus,
              vlrBonusRecebido: obj.vlrBonusRecebido,
              vlrSaldoCartao: obj.vlrSaldoCartao,
            });
          } else {
            setData({
              veiculoComp: obj.veiculoComp,
              veiculoCompB: obj.veiculoCompB,
              veiculoCompC: obj.veiculoCompC,
              placasVinculadas: obj.placasVinculadas,
              nrSeqAcertoMotorista: valor.nrSeqAcertoMotorista,
              coletaEmbarqueS: obj.coletaEmbarqueS,
              viagens: obj.viagens,
              premios: obj.viagens,
              outrasDespesas: obj.outrasDespesas,
              outrasReceitasComissao: lista,
              abastecimentos: obj.abastecimentos,
              acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
              acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
              acertoMotoristaTipoDescontoPeriodo:
                obj.acertoMotoristaTipoDescontoPeriodo,
              acertoMotoristaTipoDescontoPeriodoS:
                obj.acertoMotoristaTipoDescontoPeriodo,
              adiantamentos: obj.adiantamentos,
              bonus: obj.bonus,
              cdAcertoMotorista: obj.cdAcertoMotorista,
              cliente: obj.cliente,
              dtAcerto: obj.dtAcerto,
              dtAlteracao: obj.dtAlteracao,
              dtCadastro: obj.dtCadastro,
              dtCheque: obj.dtCheque,
              extratoFinanceiroS: obj.extratoFinanceiroS,
              formaPagamento: obj.formaPagamento,
              manutencaoVeiculoS: obj.manutencaoVeiculoS,
              mediaKM: obj.mediaKM,
              mediaOutrosKM: obj.mediaOutrosKM,
              motorista: obj.motorista,
              motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
              nrCheque: obj.nrCheque,
              nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
              nrSeqPessoaMot: obj.nrSeqPessoaMot,
              nrSeqTituloPagar: obj.nrSeqTituloPagar,
              nrSeqTituloReceber: obj.nrSeqTituloReceber,
              nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
              nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
              nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
              nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
              pedagioS: obj.pedagioS,
              pesoReceitas: obj.pesoReceitas,
              pesoTotal: obj.pesoTotal,
              pesoTotalReceitas: obj.pesoTotalReceitas,
              pessoaMot: obj.pessoaMot,
              recebimentos: obj.recebimentos,
              tipoAcertoMotorista: obj.tipoAcertoMotorista,
              tipoReceita: obj.tipoReceita,
              totalAbastecimentos: obj.totalAbastecimentos,
              totalAbastecimentosVista: obj.totalAbastecimentosVista,
              totalAdiantamentos: obj.totalAdiantamentos,
              totalComissao: obj.totalComissao,
              totalConhecimento: obj.totalConhecimento,
              totalKMRodados: obj.totalKMRodados,
              totalLitros: obj.totalLitros,
              totalManutencoes: obj.totalManutencoes,
              totalManutencoesAVista: obj.totalManutencoesAVista,
              totalMotorista: obj.totalMotorista,
              totalOutrasDespesas: obj.totalOutrasDespesas,
              totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
              totalPedagios: obj.totalPedagios,
              totalPeso: obj.totalPeso,
              totalReceitas: obj.totalReceitas,
              veiculo: obj.veiculo,
              vlrAcerto: obj.vlrAcerto,
              vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
              vlrBonus: obj.vlrBonus,
              vlrBonusRecebido: obj.vlrBonusRecebido,
              vlrSaldoCartao: obj.vlrSaldoCartao,
            });
          }
        } else if (obj.nrSeqAcertoMotorista > 0) {
          setData({
            veiculoComp: obj.veiculoComp,
            veiculoCompB: obj.veiculoCompB,
            veiculoCompC: obj.veiculoCompC,
            placasVinculadas: obj.placasVinculadas,
            coletaEmbarqueS: obj.coletaEmbarqueS,
            viagens: obj.viagens,
            outrasDespesas: obj.outrasDespesas,
            outrasReceitasComissao: lista,
            abastecimentos: obj.abastecimentos,
            acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
            acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
            acertoMotoristaTipoDescontoPeriodo:
              obj.acertoMotoristaTipoDescontoPeriodo,
            acertoMotoristaTipoDescontoPeriodoS:
              obj.acertoMotoristaTipoDescontoPeriodo,
            adiantamentos: obj.adiantamentos,
            bonus: obj.bonus,
            cdAcertoMotorista: obj.cdAcertoMotorista,
            cliente: obj.cliente,
            dtAcerto: obj.dtAcerto,
            dtAlteracao: obj.dtAlteracao,
            dtCadastro: obj.dtCadastro,
            dtCheque: obj.dtCheque,
            extratoFinanceiroS: obj.extratoFinanceiroS,
            formaPagamento: obj.formaPagamento,
            manutencaoVeiculoS: obj.manutencaoVeiculoS,
            mediaKM: obj.mediaKM,
            mediaOutrosKM: obj.mediaOutrosKM,
            motorista: obj.motorista,
            motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
            nrCheque: obj.nrCheque,
            nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
            nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
            nrSeqPessoaMot: obj.nrSeqPessoaMot,
            nrSeqTituloPagar: obj.nrSeqTituloPagar,
            nrSeqTituloReceber: obj.nrSeqTituloReceber,
            nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
            nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
            nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
            nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
            pedagioS: obj.pedagioS,
            pesoReceitas: obj.pesoReceitas,
            pesoTotal: obj.pesoTotal,
            pesoTotalReceitas: obj.pesoTotalReceitas,
            pessoaMot: obj.pessoaMot,
            recebimentos: obj.recebimentos,
            tipoAcertoMotorista: obj.tipoAcertoMotorista,
            tipoReceita: obj.tipoReceita,
            totalAbastecimentos: obj.totalAbastecimentos,
            totalAbastecimentosVista: obj.totalAbastecimentosVista,
            totalAdiantamentos: obj.totalAdiantamentos,
            totalComissao: obj.totalComissao,
            totalConhecimento: obj.totalConhecimento,
            totalKMRodados: obj.totalKMRodados,
            totalLitros: obj.totalLitros,
            totalManutencoes: obj.totalManutencoes,
            totalManutencoesAVista: obj.totalManutencoesAVista,
            totalMotorista: obj.totalMotorista,
            totalOutrasDespesas: obj.totalOutrasDespesas,
            totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
            totalPedagios: obj.totalPedagios,
            totalPeso: obj.totalPeso,
            totalReceitas: obj.totalReceitas,
            veiculo: obj.veiculo,
            vlrAcerto: obj.vlrAcerto,
            vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
            vlrBonus: obj.vlrBonus,
            vlrBonusRecebido: obj.vlrBonusRecebido,
            vlrSaldoCartao: obj.vlrSaldoCartao,
          });
        } else {
          setData({
            veiculoComp: obj.veiculoComp,
            veiculoCompB: obj.veiculoCompB,
            veiculoCompC: obj.veiculoCompC,
            placasVinculadas: obj.placasVinculadas,
            nrSeqAcertoMotorista: valor.nrSeqAcertoMotorista,
            coletaEmbarqueS: obj.coletaEmbarqueS,
            viagens: obj.viagens,
            outrasDespesas: obj.outrasDespesas,
            outrasReceitasComissao: lista,
            abastecimentos: obj.abastecimentos,
            acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
            acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
            acertoMotoristaTipoDescontoPeriodo:
              obj.acertoMotoristaTipoDescontoPeriodo,
            acertoMotoristaTipoDescontoPeriodoS:
              obj.acertoMotoristaTipoDescontoPeriodo,
            adiantamentos: obj.adiantamentos,
            bonus: obj.bonus,
            cdAcertoMotorista: obj.cdAcertoMotorista,
            cliente: obj.cliente,
            dtAcerto: obj.dtAcerto,
            dtAlteracao: obj.dtAlteracao,
            dtCadastro: obj.dtCadastro,
            dtCheque: obj.dtCheque,
            extratoFinanceiroS: obj.extratoFinanceiroS,
            formaPagamento: obj.formaPagamento,
            manutencaoVeiculoS: obj.manutencaoVeiculoS,
            mediaKM: obj.mediaKM,
            mediaOutrosKM: obj.mediaOutrosKM,
            motorista: obj.motorista,
            motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
            nrCheque: obj.nrCheque,
            nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
            nrSeqPessoaMot: obj.nrSeqPessoaMot,
            nrSeqTituloPagar: obj.nrSeqTituloPagar,
            nrSeqTituloReceber: obj.nrSeqTituloReceber,
            nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
            nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
            nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
            nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
            pedagioS: obj.pedagioS,
            pesoReceitas: obj.pesoReceitas,
            pesoTotal: obj.pesoTotal,
            pesoTotalReceitas: obj.pesoTotalReceitas,
            pessoaMot: obj.pessoaMot,
            recebimentos: obj.recebimentos,
            tipoAcertoMotorista: obj.tipoAcertoMotorista,
            tipoReceita: obj.tipoReceita,
            totalAbastecimentos: obj.totalAbastecimentos,
            totalAbastecimentosVista: obj.totalAbastecimentosVista,
            totalAdiantamentos: obj.totalAdiantamentos,
            totalComissao: obj.totalComissao,
            totalConhecimento: obj.totalConhecimento,
            totalKMRodados: obj.totalKMRodados,
            totalLitros: obj.totalLitros,
            totalManutencoes: obj.totalManutencoes,
            totalManutencoesAVista: obj.totalManutencoesAVista,
            totalMotorista: obj.totalMotorista,
            totalOutrasDespesas: obj.totalOutrasDespesas,
            totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
            totalPedagios: obj.totalPedagios,
            totalPeso: obj.totalPeso,
            totalReceitas: obj.totalReceitas,
            veiculo: obj.veiculo,
            vlrAcerto: obj.vlrAcerto,
            vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
            vlrBonus: obj.vlrBonus,
            vlrBonusRecebido: obj.vlrBonusRecebido,
            vlrSaldoCartao: obj.vlrSaldoCartao,
          });
        }
      } else {
        onSetMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }

      setLoading(false);
    };

    const vinculaReceitas = async (value) => {
      let lista = gridView.current ? gridView.current.getDataSource() : [];

      if (lista !== undefined && lista !== null) {
        lista = lista.concat(value);
      } else {
        lista = [];
        lista = lista.concat(value);
      }

      const outrasReceitasComissao = [];

      if (lista !== null) {
        if (lista !== undefined) {
          for (let i = 0; i < lista.length; ) {
            if (lista[i].flgGeraComissao === true) {
              outrasReceitasComissao.push(lista[i]);
            }
            i += 1;
          }
        }
      }

      refreshAcerto(lista, outrasReceitasComissao);

      if (gridView && gridView.current) gridView.current.setDataSource(lista);
    };

    return (
      <div>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='display-8'>7 - Receitas</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='row'>
                    <div className='col-2 mb-3'>
                      <Switch
                        formControl
                        label='A Vista'
                        checked={receitas.flgAvista}
                        onChange={(flgAvista) =>
                          setReceitas({ ...receitas, flgAvista })
                        }
                      />
                    </div>
                    <div className='col-2 mb-3'>
                      <Switch
                        formControl
                        label='Gera Comissão'
                        checked={receitas.flgGeraComissao}
                        onChange={(flgGeraComissao) =>
                          setReceitas({ ...receitas, flgGeraComissao })
                        }
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <Panel>
                      <Panel.Header
                        title='Dados Cliente'
                        theme={Theme.Light}
                        align={JustifyContent.Start}
                      />

                      <Panel.Body>
                        <div className='row'>
                          <div className='col-4  mb-3'>
                            <Autocomplete
                              label='Cliente'
                              required
                              searchDataSource={onSearchCliente}
                              selectedItem={receitas.cliente}
                              onSelectItem={(cliente) => {
                                setReceitas({
                                  ...receitas,
                                  cliente,
                                  nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                                });
                              }}
                              dataSourceTextProperty='noPessoa'
                              findTransaction={findTransaction}
                              onOpenTransaction={onOpenTransaction}
                              transactionToOpen={TransacationCodes.Cliente}
                              nrseqTransactionToOpen='nrSeqPessoaCli'
                            />
                          </div>
                          <div className='col-2  mb-3'>
                            <Textbox
                              label='Referência'
                              readOnly
                              text={receitas.referenciaCliente}
                              onChangedValue={(referenciaCliente) =>
                                setReceitas({ ...receitas, referenciaCliente })
                              }
                            />
                          </div>
                          <div className='col-2  mb-3'>
                            <Textbox
                              label='Contato'
                              readOnly
                              text={receitas.contatoCliente}
                              onChangedValue={(contatoCliente) =>
                                setReceitas({ ...receitas, contatoCliente })
                              }
                            />
                          </div>
                          <div className='col-2  mb-3'>
                            <Textbox
                              label='Número'
                              text={receitas.numeroCliente}
                              onChangedValue={(numeroCliente) =>
                                setReceitas({ ...receitas, numeroCliente })
                              }
                            />
                          </div>
                          <div className='col-2  mb-3'>
                            <Textbox
                              label='Tipo Documento'
                              text={receitas.tipoDocumentoCliente}
                              onChangedValue={(tipoDocumentoCliente) =>
                                setReceitas({
                                  ...receitas,
                                  tipoDocumentoCliente,
                                })
                              }
                            />
                          </div>
                        </div>
                      </Panel.Body>
                    </Panel>
                  </div>
                  <div className='row'>
                    <Panel>
                      <Panel.Header
                        title='Dados Carga'
                        theme={Theme.Light}
                        align={JustifyContent.Start}
                      />
                      <Panel.Body>
                        <div className='row'>
                          <div className='col-4  mb-3'>
                            <Textbox
                              label='Peso'
                              required
                              text={receitas.peso}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(peso) =>
                                setReceitas({ ...receitas, peso })
                              }
                            />
                          </div>
                          <div className='col-4  mb-3'>
                            <Autocomplete
                              label='Cidade Origem'
                              required
                              searchDataSource={onSearchCidadeOrigem}
                              selectedItem={receitas.enderecoRemetente}
                              onSelectItem={(enderecoRemetente) => {
                                setReceitas({
                                  ...receitas,
                                  enderecoRemetente,
                                  nrSeqCidadeOri: enderecoRemetente.nrSeqCidade,
                                });
                              }}
                              dataSourceTextProperty='noCidadeEstado'
                            />
                          </div>
                          <div className='col-4  mb-3'>
                            <Autocomplete
                              label='Cidade Destino'
                              required
                              searchDataSource={onSearchCidadeDestino}
                              selectedItem={receitas.enderecoDestino}
                              onSelectItem={(enderecoDestino) => {
                                setReceitas({
                                  ...receitas,
                                  enderecoDestino,
                                  nrSeqCidadeDes: enderecoDestino.nrSeqCidade,
                                });
                              }}
                              dataSourceTextProperty='noCidadeEstado'
                            />
                          </div>
                        </div>
                      </Panel.Body>
                    </Panel>
                  </div>
                  <div className='row mb-3'>
                    <Panel>
                      <Panel.Header
                        title='Dados Receita'
                        theme={Theme.Light}
                        align={JustifyContent.Start}
                      />
                      <Panel.Body>
                        <div className='row mb-3'>
                          <div className='col-3  mb-3'>
                            <Autocomplete
                              label='Tipo Receita Acerto'
                              required
                              searchDataSource={onSearchTipoReceita}
                              selectedItem={receitas.tipoReceitaAcerto}
                              onSelectItem={(tipoReceitaAcerto) => {
                                setReceitas({
                                  ...receitas,
                                  tipoReceitaAcerto,
                                  nrSeqTipoReceitaAcerto:
                                    tipoReceitaAcerto.nrSeqTipoReceitaAcerto,
                                });
                              }}
                              dataSourceTextProperty='noTipoReceitaAcerto'
                            />
                          </div>
                          <div className='col-3  mb-3'>
                            <Textbox
                              label='Nr. Documento'
                              required
                              readOnly={liberaDocumento.flgLiberaDocumento}
                              text={receitas.nrDocumento}
                              onChangedValue={(nrDocumento) =>
                                setReceitas({ ...receitas, nrDocumento })
                              }
                            />
                          </div>

                          <div className='col-2 mb-3'>
                            <DatePicker
                              label='Dt Vencimento'
                              required
                              text={receitas.dtReceita}
                              maxLength={10}
                              mask={MaskTypes.Date}
                              onChange={(dtReceita) =>
                                setReceitas({ ...receitas, dtReceita })
                              }
                            />
                          </div>
                          <div className='col-2 mb-3'>
                            <DatePicker
                              label='Dt Frete '
                              required
                              text={receitas.dtFrete}
                              maxLength={10}
                              mask={MaskTypes.Date}
                              onChange={(dtFrete) =>
                                setReceitas({ ...receitas, dtFrete })
                              }
                            />
                          </div>
                          {!receitas.flgAvista && (
                            <div className='col-2 mb-3'>
                              <Switch
                                formControl
                                label='Recebido '
                                checked={receitas.flgLiquidaTitulo}
                                onChange={(flgLiquidaTitulo) =>
                                  setReceitas({ ...receitas, flgLiquidaTitulo })
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div className='row'>
                          <div className='col-4  mb-3'>
                            <Textbox
                              label='Tarifa'
                              required
                              text={receitas.tarifa}
                              textRight
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(tarifa) =>
                                setReceitas({ ...receitas, tarifa })
                              }
                            />
                          </div>
                          <div className='col-4  mb-3'>
                            <Textbox
                              label='Valor'
                              required
                              textRight
                              mask={MaskTypes.DecimalCustom}
                              text={receitas.vlrReceita}
                              onChangedValue={(vlrReceita) =>
                                setReceitas({ ...receitas, vlrReceita })
                              }
                            />
                          </div>

                          {!receitas.flgAvista && (
                            <div className='col-4  mb-3'>
                              <Autocomplete
                                label='Forma Pagamento'
                                required
                                searchDataSource={onSearchFormaPagamento}
                                selectedItem={receitas.formaPagamento}
                                onSelectItem={(formaPagamento) => {
                                  setReceitas({
                                    ...receitas,
                                    formaPagamento,
                                    nrSeqFormaPagamento:
                                      formaPagamento.nrSeqFormaPagamento,
                                  });
                                }}
                                dataSourceTextProperty='noFormaPagamento'
                              />
                            </div>
                          )}

                          <div className='row mb-3'>
                            <div className='col-6'>
                              <Autocomplete
                                label='Remetente'
                                searchDataSource={onAutoCompleteRemetente}
                                selectedItem={receitas.remetente}
                                onSelectItem={(remetente) => {
                                  setReceitas({
                                    ...receitas,
                                    remetente,
                                    nrSeqPessoaRem: remetente.nrSeqPessoaRem,
                                  });
                                }}
                                dataSourceTextProperty='noPessoa'
                                findTransaction={findTransaction}
                                onOpenTransaction={onOpenTransaction}
                                transactionToOpen={TransacationCodes.Remetente}
                                nrseqTransactionToOpen='nrSeqPessoaRem'
                              />
                            </div>
                            <div className='col-5'>
                              <Autocomplete
                                label='Destinatário'
                                searchDataSource={onAutoCompleteDestinatario}
                                selectedItem={receitas.destinatario}
                                onSelectItem={(destinatario) => {
                                  setReceitas({
                                    ...receitas,
                                    destinatario,
                                    nrSeqPessoaDes: destinatario.nrSeqPessoaDes,
                                  });
                                }}
                                dataSourceTextProperty='noPessoa'
                                findTransaction={findTransaction}
                                onOpenTransaction={onOpenTransaction}
                                transactionToOpen={
                                  TransacationCodes.Destinatario
                                }
                                nrseqTransactionToOpen='nrSeqPessoaDes'
                              />
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col-5 mb-3'>
                              <Autocomplete
                                label='Empresa'
                                searchDataSource={onSearchEmpresa}
                                selectedItem={receitas.empresa}
                                onSelectItem={(empresa) => {
                                  setReceitas({
                                    ...receitas,
                                    empresa,
                                    nrSeqEmpresa: empresa.nrSeqEmpresa,
                                  });
                                }}
                                dataSourceTextProperty='noPessoa'
                              />
                            </div>
                          </div>
                        </div>
                      </Panel.Body>
                    </Panel>

                    {receitas?.flgGeraSegundoPagamento && (
                      <div className='row'>
                        <Panel>
                          <Panel.Header
                            title='Dados Receita - Saldo'
                            theme={Theme.Light}
                            align={JustifyContent.Start}
                          />
                          <Panel.Body>
                            <div className='row'>
                              <div className='col-3  mb-3'>
                                <Autocomplete
                                  label='Forma Pagamento - Saldo'
                                  required
                                  searchDataSource={onSearchFormaPagamento}
                                  selectedItem={
                                    receitas.formaPagamentoVinculado
                                  }
                                  onSelectItem={(formaPagamentoVinculado) => {
                                    setReceitas({
                                      ...receitas,
                                      formaPagamentoVinculado,
                                      nrSeqFormaPagamentoVinculado:
                                        formaPagamentoVinculado.nrSeqFormaPagamento,
                                    });
                                  }}
                                  dataSourceTextProperty='noFormaPagamento'
                                />
                              </div>
                              <div className='col-3  mb-3'>
                                <Textbox
                                  label='Valor - Saldo'
                                  required
                                  text={receitas.vlrReceitaVinculado}
                                  mask={MaskTypes.DecimalCustom}
                                  onChangedValue={(vlrReceitaVinculado) =>
                                    setReceitas({
                                      ...receitas,
                                      vlrReceitaVinculado,
                                    })
                                  }
                                />
                              </div>

                              <div className='col-2 mb-3'>
                                <DatePicker
                                  label='Data - Saldo'
                                  required
                                  text={receitas.dtReceitaVinculado}
                                  maxLength={10}
                                  mask={MaskTypes.Date}
                                  onChange={(dtReceitaVinculado) =>
                                    setReceitas({
                                      ...receitas,
                                      dtReceitaVinculado,
                                    })
                                  }
                                />
                              </div>

                              <div className='col-2 mb-3'>
                                <Switch
                                  formControl
                                  label='A Vista - Saldo '
                                  checked={receitas.flgAVistaVinculado}
                                  onChange={(flgAVistaVinculado) =>
                                    setReceitas({
                                      ...receitas,
                                      flgAVistaVinculado,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </Panel.Body>
                        </Panel>
                      </div>
                    )}
                    <div className='row'>
                      <div className='col-5 mb-3'>
                        <FileUpload
                          files={importedFile}
                          onChange={(files) => setImportedFile(files)}
                          allowedMimeTypes={[
                            MimeTypes.Types.PDF,
                            MimeTypes.Types.Image,
                            MimeTypes.Types.Word,
                          ]}
                        />
                      </div>
                      <div className='col-5  mb-3'>
                        <Textbox
                          label='Observação'
                          text={receitas.noObservacao}
                          onChangedValue={(noObservacao) =>
                            setReceitas({ ...receitas, noObservacao })
                          }
                        />
                      </div>
                    </div>

                    <div className='row mb-3'>
                      <div className='col-2 mt-3'>
                        <Button
                          theme={Theme.Primary}
                          template={Button.Templates.Button}
                          icon='plus'
                          text='Adicionar'
                          onClick={onAdicionarReceita}
                        />
                      </div>
                      {receitas?.tipoReceitaAcerto?.flgGeraSequencia && (
                        <div className='col-5 mt-3'>
                          <Button
                            theme={Theme.Primary}
                            template={Button.Templates.Button}
                            icon='plus'
                            text='Incluir Segundo Pagamento'
                            onClick={onAdicionarSegundoPagamento}
                          />
                        </div>
                      )}
                    </div>

                    <div className='row mb-3'>
                      <div className='col-6 mt-3'>
                        <Button
                          theme={Theme.Primary}
                          template={Button.Templates.Button}
                          icon='plus'
                          text='Buscar receitas ja cadastradas'
                          onClick={() => onModalReceitas()}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <Panel>
                        <Panel.Header
                          title='Receitas adicionadas'
                          theme={Theme.Light}
                          align={JustifyContent.Start}
                        />
                        <Panel.Body>
                          <div className='row mb-3'>
                            <div className='col-12'>
                              <GridView
                                ref={gridView}
                                canControlVisibility
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columns}
                                showSelectSizes={false}
                              />
                            </div>
                          </div>
                        </Panel.Body>
                      </Panel>
                    </div>

                    {message && (
                      <div className='mb-3'>
                        <Notification
                          message={message.message}
                          theme={message.theme}
                          onClose={() => onSetMessage(null)}
                        />
                      </div>
                    )}

                    <div className='row'>
                      <div className='col'>
                        <Panel>
                          <Panel.Header
                            icon={['fas', 'briefcase']}
                            title='Trocar Acerto das Receitas'
                            theme={Theme.Primary}
                            align={JustifyContent.Start}
                          />
                          <Panel.Body>
                            <div className='row'>
                              <div className='col-3'>
                                <Autocomplete
                                  label='Novo Acerto'
                                  searchDataSource={
                                    onAutoCompleteAcertoMotorista
                                  }
                                  selectedItem={acertoNew}
                                  onSelectItem={(item) => {
                                    setAcertoNew(item);
                                  }}
                                  dataSourceTextProperty='cdAcertoMotorista'
                                />
                              </div>

                              <div className='col-3 mt-3'>
                                <Button
                                  theme={Theme.Primary}
                                  template={Button.Templates.Button}
                                  icon='plus'
                                  text='Trocar de Acerto'
                                  onClick={onChangeAcerto}
                                />
                              </div>
                            </div>
                          </Panel.Body>
                        </Panel>
                      </div>
                    </div>
                    <ModalReceitas
                      show={modalReceitas}
                      data={data}
                      onClose={({ message: msg, theme, value }) => {
                        setModalReceitas(false);
                        setMessage({
                          message: msg,
                          theme,
                        });
                        if (value !== null && value !== undefined) {
                          vinculaReceitas(value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default Receitas;
